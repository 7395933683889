/* globals $ */
/* eslint-disable no-alert */

export default function initGlobal() {
  [
    '#applicant_gender',
    '#applicant_ac1_qualification',
    '#applicant_ac2_qualification',
    '#applicant_ac3_qualification',
    '#member_topic_id',
  ].forEach((el) => {
    $(el).dropdown('setting', 'transition', 'vertical flip');
  });

  // Quick nav in header
  $('.dropdown.quicknav').dropdown({ on: 'hover' });

  $('.country').dropdown({ forceSelection: false });

  // Close flash message
  $('.message.closable .close.icon').on('click', (e) => {
    e.preventDefault();
    $('.message.closable').fadeOut();
  });

  // Mark as unsuitable in Applicant#index
  $('#mark-unsuitable').on('submit', function handleSubmit(e) {
    e.preventDefault();

    const applicantNumber = this.applicant_number.value;
    const applicantCell = document.querySelector(`[data-number="${applicantNumber}"]`);

    if (!applicantCell) {
      alert(`Cannot find applicant no. ${applicantNumber}`);
      return;
    }

    const applicantId = applicantCell.dataset.id;
    const hiddenInput = this.applicant_id;
    hiddenInput.value = applicantId;

    this.submit();
  });
}
