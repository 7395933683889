<template>
  <validation-provider
    v-slot="{ errors, invalid, validated }"
    :rules="rules"
    :name="providername"
    slim
  >
    <div
      :class="`
        field
        ${classes}
        ${requiredField? 'required' : ''}
        ${validated && invalid ? 'error' : ''}
      `"
    >
      <label :for="`applicant_${id}`">{{ label }}</label>
      <input
        :id="`applicant_${id}`"
        v-model.trim="textInputvalue"
        :disabled="disabled"
        :maxlength="maxlength"
        :name="`applicant[${id}]`"
        :placeholder="placeholder"
        type="text"
        @input="$emit('input', $event.target.value)"
      >
      <div
        v-if="errors[0]"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
/*
  The @input handler is for the GPA fields that disable themselves based on other fields

  Props:
  - classes: additional class names to pass to semantic UI (e.g. 3 fields)
  - disabled: text input's disabled state
  - id: text input's id - prepended with 'applicant_'
  - label: text input's label text
  - maxlength: text input's maxlength attribute (e.g. programme length)
  - placeholder: text input's placeholder text
  - requiredField: boolean indicating if the label should indicate that this field is required
  - rules: validation rules to be passed to the provider
*/
import { ValidationProvider } from 'vee-validate';
import registerRules from '../../helpers/rules';

registerRules([
  'between',
  'date_format',
  'date_between',
  'decimal',
  'email',
  'greaterThanZero',
  'min',
  'notGreaterThan',
  'numeric',
  'required',
  'validDate',
  'validGre',
  'validPhoneNumber',
  'validUrl',
]);

export default {
  name: 'TextInput',
  components: {
    ValidationProvider,
  },
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maxlength: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: true,
    },
    providername: {
      type: String,
      required: false,
      default: '',
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      textInputvalue: '',
    };
  },
};
</script>
