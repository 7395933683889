// http://blogs.longwin.com.tw/lifetype/key_codes.html
// https://codepen.io/rdjpalmer/pen/MYjgJN
function applyDateMask(e) {
  /*
    Receives keypress event object
    { target: input#applicant_ac1_start_date, key: "1", charCode: 49, keyCode: 0 }
  */
  const len = e.target.value.length;

  // If the applyDateMask function is called from a DateInput component it will have a 'pattern' prop
  // If it is called on a regular input element, it will have a 'pattern' data property
  const pattern = (this.pattern === undefined) ? e.target.dataset.pattern : this.pattern;

  switch (pattern) {
    case 'DD/MM/YYYY':
      if (e.keyCode < 47 || e.keyCode > 57) e.preventDefault();

      if (len !== 1 || len !== 3) {
        if (e.keyCode === 47) e.preventDefault();
      }

      if (len === 2 || len === 5) e.target.value += '/';
      break;
    case 'MM/YYYY':
      if (e.keyCode < 47 || e.keyCode > 57) e.preventDefault();

      if (len !== 1) {
        if (e.keyCode === 47) e.preventDefault();
      }

      if (len === 2) e.target.value += '/';
      break;
    default:
      // Whatever
  }
}

export default applyDateMask;
