<template>
  <validation-provider
    v-slot="{ errors, invalid, validated }"
    :rules="rules"
    slim
  >
    <div
      :class="`
        field
        ${requiredField? 'required' : ''}
        ${validated && invalid ? 'error' : ''}
      `"
    >
      <label :for="`applicant_${id}`">{{ label }}</label>
      <div
        ref="countryDropdown"
        class="ui fluid search selection country dropdown"
      >
        <input
          v-model="selectValue"
          type="hidden"
          :name="`applicant[${id}]`"
          @change="(e) => { selectValue = e.target.value; $emit('update', e.target.value) }"
        >
        <i class="dropdown icon" />
        <input
          :id="`applicant_${id}`"
          class="search"
          autocomplete="off"
          tabindex="0"
        >
        <div class="default text">
          {{ placeholder }}
        </div>
      </div>

      <div
        v-if="errors[0]"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
/* globals $ */

/*
  The @change handler ensures that the model is updated, which means validation can work
  The @change handler also emits because ...

  Props:
  - id: select's id - prepended with 'applicant_'
  - label: select's label text
  - opts: array containing options for select
  - placeholder: select's placeholder text. As you can search in the field, no prompt necessary
  - requiredField: boolean indicating if the label should indicate that this field is required
  - rules: validation rules to be passed to the provider
*/
import { ValidationProvider } from 'vee-validate';
import registerRules from '../../helpers/rules';

registerRules(['required']);

export default {
  name: 'CountrySelect',
  components: {
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectValue: '',
    };
  },
  mounted() {
    $(this.$refs.countryDropdown).dropdown({
      apiSettings: {
        url: '/countries/index.json',
      },
      filterRemoteData: true,
      forceSelection: false,
    });
  },
  destroyed() {
    $(this.$refs.countryDropdown).dropdown('destroy');
  },
};
</script>
