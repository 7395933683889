<template>
  <div id="app">
    <ValidationObserver ref="form">
      <form
        id="new_applicant"
        class="ui form segment"
        action="/applicants"
        accept-charset="UTF-8"
        method="post"
        @submit.prevent="onSubmit"
      >
        <input
          type="hidden"
          name="authenticity_token"
          value=""
        >
        <!-- FORM HERE -->
        <ProgrammeSelect @updateProgramme="updateProgramme" />
        <PersonalInformation />
        <CorrespondenceAddress />
        <AcademicHistory />
        <Awards />
        <AdditionalExams />
        <LanguageTests />
        <WorkExperience />
        <Referees />
        <ScientificInterests />
        <TextAreas :show-financial-statement="programme !== 'DAAD'" />
        <TermsAndConditions />

        <div :class="`ui error message ${errorClass}`">
          Unfortunately, one or more errors prevented this form from being submitted.
        </div>
        <button class="ui blue submit button">
          Submit
        </button>
      </form>
    </ValidationObserver>

    <Modal />
  </div>
</template>

<script>
import { getEventBus, events } from 'notivuecation';
import { setInteractionMode, ValidationObserver } from 'vee-validate';
import CorrespondenceAddress from './main/correspondence-address.vue';
import PersonalInformation from './main/personal-information.vue';
import ProgrammeSelect from './main/programme-select.vue';

setInteractionMode('eager');

export default {
  name: 'App',
  components: {
    CorrespondenceAddress,
    PersonalInformation,
    ProgrammeSelect,
    ValidationObserver,
    AcademicHistory: () => import(/* webpackChunkName: "below-fold" */ './main/academic-history.vue'),
    AdditionalExams: () => import(/* webpackChunkName: "below-fold" */ './main/additional-exams.vue'),
    Awards: () => import(/* webpackChunkName: "below-fold" */ './main/awards.vue'),
    LanguageTests: () => import(/* webpackChunkName: "below-fold" */ './main/language-tests.vue'),
    Modal: () => import(/* webpackChunkName: "below-fold" */ './generic/modal.vue'),
    Referees: () => import(/* webpackChunkName: "below-fold" */ './main/referees.vue'),
    ScientificInterests: () => import(/* webpackChunkName: "below-fold" */ './main/scientific-interests.vue'),
    TermsAndConditions: () => import(/* webpackChunkName: "below-fold" */ './main/terms-and-conditions.vue'),
    TextAreas: () => import(/* webpackChunkName: "below-fold" */ './main/text-areas.vue'),
    WorkExperience: () => import(/* webpackChunkName: "below-fold" */ './main/work-experience.vue'),
  },
  data() {
    return {
      errorClass: '',
      programme: '',
      submitButtonIsDisabled: false,
    };
  },
  mounted() {
    // Stop page from scrolling when modal is displayed
    // https://github.com/petervdn/notivuecation/issues/15
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

    getEventBus().$on(events.SHOW_NOTIFICATION, () => {
      document.body.classList.add('modal-open');
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    });

    getEventBus().$on(events.HIDE_NOTIFICATION, () => {
      document.body.classList.remove('modal-open');
      document.body.style.paddingRight = 0;
    });
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.errorClass = 'visible';
        } else {
          this.submitButtonIsDisabled = true;
          document.forms[0].submit();
        }
      });
    },
    updateProgramme(value) {
      this.programme = value;
    },
  },
};
</script>
