<template>
  <validation-provider
    v-slot="{ errors, invalid, validated }"
    slim
    rules="required"
  >
    <div :class="`field ${validated && invalid ? 'error' : ''}`">
      <p><strong>Select your programme:</strong><span class="required">*</span></p>

      <div class="grouped fields">
        <div class="field">
          <div class="ui radio checkbox">
            <input
              id="applicant_programme_phd"
              v-model="buttonValue"
              name="applicant[programme]"
              type="radio"
              value="PhD"
              @change="updateProgramme"
            >
            <label for="applicant_programme_phd">
              <strong>PhD / Doctorate programme (3 years).</strong><br>
              For applicants with a Master of Science degree or equivalent
            </label>
          </div>
        </div>
        <div class="field">
          <div class="ui radio checkbox">
            <input
              id="applicant_programme_daad"
              v-model="buttonValue"
              name="applicant[programme]"
              type="radio"
              value="DAAD"
              @change="updateProgramme"
            >
            <label for="applicant_programme_daad">
              <strong>DAAD Scholarships / PhD Doctorate programme (3 years).</strong><br>
              For applicants with a Master of Science degree or equivalent
            </label>
          </div>
        </div>
        <div class="field">
          <div class="ui radio checkbox">
            <input
              id="applicant_programme_bsc"
              v-model="buttonValue"
              name="applicant[programme]"
              type="radio"
              value="Bsc"
              @change="updateProgramme"
            >
            <label for="applicant_programme_bsc">
              <strong>Programme for applicants with a Bachelor degree or equivalent (4 years).</strong><br>
              Includes 1 year of preparatory training before entering the 3-year PhD programme
            </label>
          </div>
        </div>
      </div>
      <div
        v-if="errors[0]"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import registerRules from '../../helpers/rules';

registerRules(['required']);

export default {
  name: 'ProgrammeSelect',
  components: {
    ValidationProvider,
  },
  data() {
    return {
      buttonValue: '',
    };
  },
  methods: {
    updateProgramme(event) {
      this.$emit('updateProgramme', event.target.value);
    },
  },
};
</script>
