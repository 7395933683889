<template>
  <validation-provider
    v-slot="{ errors, invalid, validated }"
    :rules="rules"
    :name="providername"
    slim
  >
    <div
      :class="`
        field
        ${requiredField? 'required' : ''}
        ${validated && invalid ? 'error' : ''}
      `"
    >
      <label :for="`applicant_${id}`">{{ label }}</label>
      <input
        :id="`applicant_${id}`"
        v-model="dateInputValue"
        :data-pattern="pattern"
        :maxlength="maxlength"
        :name="`applicant[${id}]`"
        :placeholder="placeholder"
        type="text"
        @keypress="applyDateMask($event)"
        @input="$emit('input', $event.target.value)"
      >

      <div
        v-if="errors[0]"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
/*
  The @keypress handler ensures that only numbers and slashes can be entered into the field
  The @input handler is for ...

  Props:
  - id: date input's id - prepended with 'applicant_'
  - label: date input's label text
  - maxlength: date input's maxlength attribute (dates are either MM/YYYY or DD/MM/YYYY)
  - pattern: converted into a data-pattern attribute, used by the input mask
  - placeholder: text input's placeholder text
  - provider name: allows validation of dependent fields - replaces DateRange component
  - requiredField: boolean indicating if the label should indicate that this field is required
  - rules: validation rules to be passed to the provider
*/
import { ValidationProvider } from 'vee-validate';
import applyDateMask from '../../helpers/date-input-mask';
import registerRules from '../../helpers/rules';

registerRules([
  'after',
  'isBeforeCourseStartDate',
  'required',
]);

export default {
  name: 'DateInput',
  components: {
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    maxlength: {
      type: String,
      required: true,
    },
    pattern: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    providername: {
      type: String,
      required: false,
      default: '',
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      dateInputValue: '',
    };
  },
  methods: {
    applyDateMask,
  },
};
</script>
