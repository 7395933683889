<template>
  <validation-provider
    v-slot="{ errors, invalid, validated }"
    :rules="rules"
    slim
  >
    <div
      :class="`
        field
        ${requiredField? 'required' : ''}
        ${validated && invalid ? 'error' : ''}
      `"
    >
      <label :for="`applicant_${id}`">{{ label }}</label>
      <select
        :id="`applicant_${id}`"
        ref="myDropdown"
        v-model="selectValue"
        class="ui fluid dropdown"
        :name="`applicant[${id}]`"
        @change="$emit('input', $event.target.value)"
      >
        <option value="">
          {{ prompt }}
        </option>
        <option
          v-for="option in opts"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </select>
      <div
        v-if="errors[0]"
        class="ui basic red pointing prompt label transition error-message"
      >
        {{ errors[0] }}
      </div>
    </div>
  </validation-provider>
</template>

<script>
/* globals $ */

/*
  The @change handler is for ...

  Props:
  - id: select's id - prepended with 'applicant_'
  - label: select's label text
  - opts: array containing options for select
  - prompt: select's prompt text
  - requiredField: boolean indicating if the label should indicate that this field is required
  - rules: validation rules to be passed to the provider
*/
import { ValidationProvider } from 'vee-validate';
import registerRules from '../../helpers/rules';

registerRules(['required']);

export default {
  name: 'Select',
  components: {
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    opts: {
      type: Array,
      required: true,
    },
    prompt: {
      type: String,
      required: true,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectValue: '',
    };
  },
  mounted() {
    $(this.$refs.myDropdown).dropdown({ transition: 'vertical flip' });
  },
  destroyed() {
    $(this.$refs.countryDropdown).dropdown('destroy');
  },
};
</script>
