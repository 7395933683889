<template>
  <div>
    <div class="form-section">
      <h4>Correspondence Address</h4>
      <p>All correspondence will be mailed to this address</p>
    </div>

    <div class="two fields">
      <TextInput
        id="correspondence_address_street"
        label="Street"
        placeholder="Street"
        required-field
        rules="required"
      />
      <TextInput
        id="correspondence_address_supplement"
        label="Supplement"
        placeholder="Supplement"
      />
    </div>

    <div class="two fields">
      <TextInput
        id="correspondence_address_city"
        label="City"
        placeholder="City"
        required-field
        rules="required"
      />
      <TextInput
        id="correspondence_address_district"
        label="State/District"
        placeholder="State/District"
      />
    </div>

    <div class="two fields">
      <TextInput
        id="correspondence_address_zip"
        label="Zip/Postal Code"
        placeholder="Zip/Postal Code"
        required-field
        rules="required"
      />
      <CountrySelect
        id="correspondence_address_country"
        label="Country"
        placeholder="Country"
        required-field
        rules="required"
      />
    </div>

    <div class="two fields">
      <TextInput
        id="correspondence_address_tel"
        label="Telephone"
        placeholder="Telephone"
        required-field
        rules="required|validPhoneNumber|min:10"
      />
      <TextInput
        id="email"
        label="Email Address"
        placeholder="Email Address"
        required-field
        rules="required|email"
      />
    </div>
  </div>
</template>

<script>
import CountrySelect from '../generic/country-select.vue';
import TextInput from '../generic/text-input.vue';

export default {
  name: 'CorrespondenceAddress',
  components: {
    CountrySelect,
    TextInput,
  },
};
</script>
