/* eslint import/first: 0 */
require('@rails/ujs').start();

import '../../../vendor/fomantic-ui/dist/components/breadcrumb.min.css';
import '../../../vendor/fomantic-ui/dist/components/button.min.css';
import '../../../vendor/fomantic-ui/dist/components/checkbox.min.css';
import '../../../vendor/fomantic-ui/dist/components/container.min.css';
import '../../../vendor/fomantic-ui/dist/components/divider.min.css';
import '../../../vendor/fomantic-ui/dist/components/dropdown.min.css';
import '../../../vendor/fomantic-ui/dist/components/flag.min.css';
import '../../../vendor/fomantic-ui/dist/components/form.min.css';
import '../../../vendor/fomantic-ui/dist/components/grid.min.css';
import '../../../vendor/fomantic-ui/dist/components/header.min.css';
import '../../../vendor/fomantic-ui/dist/components/icon.min.css';
import '../../../vendor/fomantic-ui/dist/components/input.min.css';
import '../../../vendor/fomantic-ui/dist/components/item.min.css';
import '../../../vendor/fomantic-ui/dist/components/label.min.css';
import '../../../vendor/fomantic-ui/dist/components/loader.min.css';
import '../../../vendor/fomantic-ui/dist/components/list.min.css';
import '../../../vendor/fomantic-ui/dist/components/message.min.css';
import '../../../vendor/fomantic-ui/dist/components/placeholder.min.css';
import '../../../vendor/fomantic-ui/dist/components/reset.min.css';
import '../../../vendor/fomantic-ui/dist/components/segment.min.css';
import '../../../vendor/fomantic-ui/dist/components/site.min.css';
import '../../../vendor/fomantic-ui/dist/components/table.min.css';
import '../../../vendor/fomantic-ui/dist/components/transition.min.css';

import '../../assets/stylesheets/styles.css';

import '../../../vendor/fomantic-ui/dist/components/api.min';
import '../../../vendor/fomantic-ui/dist/components/dropdown.min';
import '../../../vendor/fomantic-ui/dist/components/transition.min';

import '../src/main';
