import { extend } from 'vee-validate';
import {
  email, min, numeric, required,
} from 'vee-validate/dist/rules';

import {
  isAfter, isBetween, isChronological, isCorrectFormat, isDecimal, isValidDate,
} from './helper';

import COURSE_START_DATE from './globals';

const availableRules = {
  email: { ...email, message: 'This is not a valid email address' },
  min: { ...min, message: 'This field must contain at least 10 characters' },
  numeric: { ...numeric, message: 'This field must be a whole integer' },
  required: { ...required, message: 'This field is required' },

  // CUSTOM

  after: {
    params: ['startDate', 'name'],
    validate: (value, { startDate }) => isChronological(value, startDate),
    message: 'This must be later than {name} start date',
  },

  between: {
    validate: (value, { from, to }) => value >= Number(from) && value <= Number(to),
    params: ['from', 'to'],
    message: 'This field must be between {from} and {to}',
  },

  date_between: {
    validate: (value, { dateFrom, dateTo }) => isBetween(value, dateFrom, dateTo),
    params: ['dateFrom', 'dateTo'],
    message: 'This field must be between {dateFrom} and {dateTo}',
  },

  date_format: {
    validate: (value, { format }) => isCorrectFormat(value, format),
    params: ['format'],
    message: 'This field must be in the format {format}',
  },

  decimal: {
    validate: (value, { places }) => isDecimal(value, places),
    params: ['places'],
    message: 'This field must be numeric and may contain {places} decimal places',
  },

  greaterThanZero: {
    validate: value => value > 0,
    message: 'This field must be greater than zero',
  },

  notGreaterThan: {
    params: ['field2', 'fieldName'],
    validate: (value, { field2 }) => {
      // If dependent field has not been filled out, or contains no value
      if (!field2) return true;

      // If dependent field is not a number
      if (Number.isNaN(Number(field2))) return true;

      return Number(value) <= Number(field2);
    },
    message: 'This may not be greater than {fieldName}',
  },

  isBeforeCourseStartDate: {
    validate: value => isAfter(value, COURSE_START_DATE),
    message: 'This is after the course has started',
  },

  maximumLength: {
    validate: (value, { number }) => value.length <= Number(number),
    params: ['number'],
    message: ' This field may not be greater than {number} characters ',
  },

  validDate: {
    validate: value => isValidDate(value),
    message: 'This is not a valid date',
  },

  validGre: {
    validate: value => /^[0-9,.]+$/.test(value),
    message: 'This field can only contain numbers, decimal points or commas ',
  },

  validPhoneNumber: {
    validate: value => value.match(/^[0-9()+\s-]*$/),
    message: 'This field may only contain digits or + - ( )',
  },

  validUrl: {
    validate: value => value.match(/^[^\s]+\.[^\s]+$/i),
    message: 'This is not a valid URL',
  },
};

function registerRules(desiredRules) {
  const has = Object.prototype.hasOwnProperty;

  desiredRules.forEach((ruleName) => {
    if (!has.call(availableRules, ruleName)) return;
    extend(ruleName, availableRules[ruleName]);
  });
}

export default registerRules;
