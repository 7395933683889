const COURSE_START_DATE = 'October 1 2024 00:00';

/*
WHERE ARE THESE USED?

export const MIN_YEAR = '1900';
export const MAX_YEAR = '2004';
*/

export default COURSE_START_DATE;
