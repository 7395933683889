/* eslint-disable camelcase */

import Vue from 'vue';
import notivuecation from 'notivuecation';
import App from './components/app.vue';
import initGlobal from './semantic/index';

Vue.use(notivuecation);

document.addEventListener('DOMContentLoaded', () => {
  initGlobal();

  if (window.location.pathname === '/') {
    const el = document.getElementById('outlet');

    // eslint-disable-next-line no-unused-vars
    const app = new Vue({
      el,
      render: h => h(App),
    });
  }
});
